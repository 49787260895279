.contactLink {
    color: #f2efed;
    font-size: 1rem;
}

.contactDetails {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 32px;
    padding-bottom: 16px;
    vertical-align: middle;
}